var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('splitpanes',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.setTableHeight),expression:"setTableHeight"}],staticClass:"default-theme",staticStyle:{"height":"calc(100vh - 52px)"},attrs:{"horizontal":""},on:{"resized":_vm.setTableHeight}},[_c('pane',{ref:"toppane",attrs:{"min-size":"25","size":"45"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","options":_vm.options,"server-items-length":_vm.totalItems,"dense":"","footer-props":{ itemsPerPageOptions: [10, 30, 50] },"fixed-header":"","height":_vm.tableheight,"loading":_vm.loading,"loading-text":"加载中...","show-select":"","single-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{ref:"tabletopbar",staticClass:"d-flex flex-wrap pb-1",staticStyle:{"gap":"16px"}},[_c('v-text-field',{staticClass:"ml-2",staticStyle:{"flex":"0 1 auto"},attrs:{"placeholder":"姓名/证件号/手机号","clearable":"","hide-details":"","append-icon":"search"},on:{"click:append":_vm.loadData},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadData($event)}},model:{value:(_vm.psearch),callback:function ($$v) {_vm.psearch=$$v},expression:"psearch"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","width":"240px"},attrs:{"value":_vm.pdates.join(' ~ '),"label":"预约解读日期","readonly":"","hide-details":"","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.pdates=[]}}},'v-text-field',attrs,false),on))]}}],null,false,1760789578),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"range":"","locale":"zh-cn","scrollable":""},on:{"change":function($event){_vm.menu1=false}},model:{value:(_vm.pdates),callback:function ($$v) {_vm.pdates=$$v},expression:"pdates"}})],1),_c('v-checkbox',{staticClass:"ml-8",attrs:{"label":"显示已完成此步骤","hide-details":""},on:{"change":_vm.loadData},model:{value:(_vm.showComplete),callback:function ($$v) {_vm.showComplete=$$v},expression:"showComplete"}})],1),_c('v-divider')]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":item.color},on:{"click":function($event){return _vm.editNote(item)}}},on),[_vm._v("edit_note")])]}}],null,true)},[_vm._v(" 患者备注 ")])]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAge(item))+" ")]}},{key:"item.opts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptsStr(item))+" ")]}},{key:"item.apntIntpDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.apntIntpDate))+" ")]}}],null,false,4123236934),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),((_vm.selected.length > 0))?_c('pane',{attrs:{"min-size":"30","size":"55"}},[_c('div',{staticClass:"overflow-y-auto pl-2",staticStyle:{"max-height":"100%","background-color":"white"}},[_c('v-tabs',{staticStyle:{"position":"sticky","top":"0","z-index":"99"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("进度")]),_c('v-tab',[_vm._v("病历")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{staticClass:"d-flex pl-4"},[_c('v-form',{staticStyle:{"width":"240px"}},[_c('v-radio-group',{attrs:{"label":"病理解读","mandatory":""},model:{value:(_vm.stgIntp),callback:function ($$v) {_vm.stgIntp=$$v},expression:"stgIntp"}},_vm._l((_vm.stgIntpItems),function(r,i){return _c('v-radio',{key:r,attrs:{"label":r,"value":i===0?null:r}})}),1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.saveStage('病理解读', _vm.stgIntp)}}},[_vm._v("保存")])],1)],1)]),_c('v-tab-item',[_c('DiagnoseHistoryEditor',{ref:"dhe",attrs:{"pat":_vm.selected[0]}})],1)],1)],1)]):_vm._e()],1)],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }